//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
export default {
    name: "Upload",
    data() {
        return {
            ossClient: new OssClient(),
            picVisible: false,
            currentPic: ''
        };
    },
    props: {
        list: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    methods: {
        uploadFile(e) {
            let file = e.target.files;
            // if (file.length === 0) return;
            if (this.list.length >= 3) {
                return
            }
            for(let i =  0; i < file.length; i++) {
                if (!this.detectionImg(file[i])) {
                    return;
                }
            }


            Object.values(file).map(item => {
                this.uploadChange(item);
            });
        },
        uploadChange(file) {
            this.ossClient
                .multipartUpload({
                    file: file
                })
                .then(res => {
                    if (this.list.length >= 3) {
                        return
                    }
                    this.list.push(res.url);
                    this.updateList();
                    this.$refs.fileInput.value = "";
                });
        },
        deleteList(index) {
            this.list.splice(index, 1);
            this.updateList();
        },
        updateList() {
            this.$emit("update:list", this.list);
        },
        detectionImg(file) {
            console.log(file)
            // const reg2 = /^(\s|\S)+(jpg|png|jpeg|JPG|PNG|JPEG)+$/;
            const typeList = ['image/jpg', 'image/jpeg', 'image/png', 'image/bmp'];
            const isLt2M = file.size / 1024 < 5 * 1024;
            if (typeList.indexOf(file.type) === -1) {
                this.$message({ type: "warning", message: "图片格式上传错误！" });
                return false;
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 5MB!");
                return false;
            }
            return true;
        },
        showFullPic(item) {
            this.picVisible = true
            this.currentPic = item
        },
    },
    created() {},
    activated() {},
    watch: {},
    computed: {}
};
