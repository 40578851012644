//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "NullData",
  data() {
    return {};
  },
  props: {
    loading: Boolean
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  },
  created() {},
  activated() {},
  watch: {},
  computed: {}
};
